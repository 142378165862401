import { FormAnswer } from 'models';
import Metrics from 'pages/[habitat]/affiliate/cycles/[cycleId]/[applicationId]/components/Metrics/Metrics';

interface IProperties {
  formAnswers: FormAnswer[];
  hasRevision?: boolean;
  currentVersion: number;
  showPrevious?: boolean;
}

const CalculationsTab = ({
  formAnswers,
  hasRevision,
  currentVersion,
  showPrevious,
}: IProperties) => {
  const originalFormAnswersWithMetrics = formAnswers.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (formAnswer: any) =>
      formAnswer.values &&
      typeof formAnswer.values.metrics === 'object' &&
      !formAnswer.isCopy &&
      (formAnswer.version || 0) ===
        (showPrevious ? currentVersion - 1 : currentVersion)
  );

  const copyFormAnswersWithMetrics = formAnswers.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (formAnswer: any) =>
      formAnswer.values &&
      typeof formAnswer.values.metrics === 'object' &&
      formAnswer.isCopy
  );

  const formAnswerWithMetrics =
    hasRevision && copyFormAnswersWithMetrics
      ? copyFormAnswersWithMetrics
      : originalFormAnswersWithMetrics;

  const metrics: {
    [key: string]: {
      type?: 'percentage' | 'currency' | 'number';
      label?: string;
      header?: string;
      value?: string | number;
    };
  } = (
    formAnswerWithMetrics as unknown as {
      values: {
        metrics: {
          [key: string]: {
            type?: 'percentage' | 'currency' | 'number';
            label?: string;
            value?: string | number;
            header?: string;
          };
        };
      };
    }
  )?.values?.metrics;

  return (
    <div>
      <Metrics data={metrics} />
    </div>
  );
};

export default CalculationsTab;
