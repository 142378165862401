import { get } from 'aws-amplify/api';
import { DataStore } from 'aws-amplify/datastore';
import { Decision, RootForm, TestApplication, TestCycle, User } from 'models';
import { defer, LoaderFunction } from 'react-router-dom';
import { untilDataStoreIsReady } from 'utils/dataStore';

export interface IUsersOfApplications extends User {
  attributes: {
    email: string;
    phone: string;
  };
}

const getUserData = async (userId: string | undefined) => {
  await untilDataStoreIsReady();

  const users = await DataStore.query(User, (c) => c.owner.eq(userId || ''));

  if (users.length === 0) {
    throw new Response('User not Found', { status: 404 });
  }

  const user = users[0];

  const applications = await DataStore.query(TestApplication, (c) =>
    c.ownerID.eq(userId || '')
  );

  const decisions = await DataStore.query(Decision, (c) =>
    c.or((c2) =>
      applications.map((application) => c2.testapplicationID.eq(application.id))
    )
  );

  const cyclesIds: string[] = [];

  applications.forEach((application) => {
    if (
      application.testcycleID &&
      !cyclesIds.includes(application.testcycleID)
    ) {
      cyclesIds.push(application.testcycleID);
    }
  });

  const cycles = await DataStore.query(TestCycle, (c) =>
    c.or((c2) => cyclesIds.map((cycleId) => c2.id.eq(cycleId)))
  );

  const rootFormsIds: string[] = [];

  cycles.forEach((cycle) => {
    if (cycle.rootformID && !rootFormsIds.includes(cycle.rootformID)) {
      rootFormsIds.push(cycle.rootformID);
    }
  });

  const rootForms = await DataStore.query(RootForm, (c) =>
    c.or((c2) => rootFormsIds.map((rootFormId) => c2.id.eq(rootFormId)))
  );

  const attributesResponse = user.owner
    ? await get({
        apiName: 'userAPI',
        path: '/',
        options: {
          queryParams: {
            sub: user.owner,
          },
        },
      }).response
    : undefined;

  if (attributesResponse?.statusCode !== 200) {
    throw new Response('User does not exist.', { status: 404 });
  }

  const body = (await attributesResponse.body.json()) as {
    attributes: { Name: string; Value: string }[];
  };

  const { attributes } = body;

  return {
    user,
    decisions,
    cycles,
    rootForms,
    attributes,
    applications,
  };
};

export type TUserData = Awaited<Promise<ReturnType<typeof getUserData>>>;

const userLoader: LoaderFunction = async ({ params }) => {
  const { userId } = params;

  return defer({ userData: getUserData(userId) });
};

export default userLoader;
