import { DataStore } from 'aws-amplify/datastore';
import { Hub } from 'aws-amplify/utils';

let dataStoreGotSynced = false;

export const untilDataStoreIsReady = async () => {
  if (!dataStoreGotSynced) {
    await DataStore.stop();
    await DataStore.start();

    const DataStorePromise = new Promise<void>((resolve, reject) => {
      try {
        const listener = Hub.listen('datastore', async (hubData) => {
          const { event } = hubData.payload;
          if (event === 'ready') {
            dataStoreGotSynced = true;
            listener();
            resolve();
          }
        });
      } catch (error) {
        reject(error);
      }
    });

    return DataStorePromise;
  }

  return new Promise<void>((resolve) => {
    resolve();
  });
};
