import { Outlet, useLocation, useLoaderData, Await } from 'react-router-dom';
import CheckMaintenance from 'layouts/Maintenance/CheckMaintenance';
import useHabitat from 'hooks/utils/useHabitat';
import { Suspense, useEffect } from 'react';
import { THabitatData } from 'router/loaders/habitat';
import { Loader } from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';
import styles from './HabitatLayout.module.css';

const HabitatLayout = () => {
  const { habitatData } = useLoaderData() as {
    habitatData: Promise<THabitatData>;
  };

  const { t } = useTranslation();

  const { setHabitat } = useHabitat();

  const { pathname } = useLocation();

  useEffect(() => {
    if (
      pathname.endsWith('/goldsboro-wayne') ||
      pathname.endsWith('/goldsboro-wayne/')
    ) {
      window.location.href =
        'https://form.habitat-app.org/jcxuakumlexxzla/manage/view/#/form/goldsboro-wayne-pre-application-1-0-0?header=0&reset=1&theme=cerulean&host=form.habitat-app.org&protocol=https';
    }
  }, [pathname]);

  useEffect(() => {
    const awaitHabitatData = async () => {
      const resolvedHabitatData = await habitatData;
      setHabitat(resolvedHabitatData);
    };

    awaitHabitatData();
  }, [habitatData, setHabitat]);

  return (
    <CheckMaintenance>
      <Suspense
        fallback={
          <div className={styles.loadingContainer}>
            <span>{t('layouts.habitatLayout.loading')}</span> <Loader />
          </div>
        }
      >
        <Await resolve={habitatData}>
          <Outlet />
        </Await>
      </Suspense>
    </CheckMaintenance>
  );
};

export default HabitatLayout;
