import { useAuthenticator, useBreakpointValue } from '@aws-amplify/ui-react';
import React, { Suspense, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DataStore } from 'aws-amplify/datastore';
import { User, ReviewStatus } from 'models';
import useAsync from 'hooks/utils/useAsync';
import TableWithPaginator from 'components/TableWithPaginator';
import CustomCard from 'components/CustomCard';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Await, Link, useLoaderData } from 'react-router-dom';
import { convertDateYYYYMMDDtoDDMMYYYY } from 'utils/dates';
import { MdOutlineOpenInNew } from 'react-icons/md';
import IconButton from 'components/IconButton';
import dayjs from 'dayjs';
import { THomeData } from 'router/loaders/home';
import Skeleton from 'components/Skeleton';
import { Status } from 'utils/enums';
import styles from './AffiliateHomePage.module.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const AffiliateHomePage = () => {
  const { t } = useTranslation();

  const { homeData } = useLoaderData() as {
    homeData: Promise<THomeData>;
  };

  const titleStyle = useBreakpointValue({
    base: 'theme-subtitle-s1',
    large: 'theme-headline-medium',
  });

  const subtitleStyle = useBreakpointValue({
    base: 'theme-body-small',
    large: 'theme-body-medium',
  });

  const { user } = useAuthenticator((context) => [context.user]);

  const aspectRatio = useBreakpointValue({
    base: 1.25,
    medium: 1.5,
    large: 1.75,
  }) as number;

  const months = [
    t('pages.habitat.affiliate.home.months.january'),
    t('pages.habitat.affiliate.home.months.february'),
    t('pages.habitat.affiliate.home.months.march'),
    t('pages.habitat.affiliate.home.months.april'),
    t('pages.habitat.affiliate.home.months.may'),
    t('pages.habitat.affiliate.home.months.june'),
    t('pages.habitat.affiliate.home.months.july'),
    t('pages.habitat.affiliate.home.months.august'),
    t('pages.habitat.affiliate.home.months.september'),
    t('pages.habitat.affiliate.home.months.october'),
    t('pages.habitat.affiliate.home.months.november'),
    t('pages.habitat.affiliate.home.months.december'),
  ];

  const getLastMonths = (numberOfMonths: number) => {
    const currentMonth = new Date().getMonth();
    let lastSixMonths: string[] = [];
    for (let i = 0; i < numberOfMonths; i++) {
      lastSixMonths = [months.at(currentMonth - i) || '', ...lastSixMonths];
    }
    return lastSixMonths;
  };

  const getUserData = useCallback(async () => {
    try {
      const userData = await DataStore.query(User, (c) =>
        c.owner.eq(user.userId)
      );

      if (userData.length === 0) {
        throw new Response('User not found', { status: 404 });
      }

      return userData[0];
    } catch (error) {
      console.log('Error fetching user data', error);
    }
  }, [user.userId]);

  const { value, status } = useAsync<User | undefined>({
    asyncFunction: getUserData,
  });

  const defaultRows = [
    {
      id: '1',
      cells: [
        {
          value: <div className={styles.dummy} />,
          id: 'name',
        },
        {
          value: '',
          id: 'dateSubmitted',
        },
        {
          value: '',
          id: 'form',
        },
        {
          value: '',
          id: 'cycle',
        },
        {
          value: '',
          id: 'view',
        },
      ],
    },
    {
      id: '2',
      cells: [
        {
          value: <div className={styles.dummy} />,
          id: 'name',
        },
        {
          value: '',
          id: 'dateSubmitted',
        },
        {
          value: '',
          id: 'form',
        },
        {
          value: '',
          id: 'cycle',
        },
        {
          value: '',
          id: 'view',
        },
      ],
    },
    {
      id: '3',
      cells: [
        {
          value: <div className={styles.dummy} />,
          id: 'name',
        },
        {
          value: '',
          id: 'dateSubmitted',
        },
        {
          value: '',
          id: 'form',
        },
        {
          value: '',
          id: 'cycle',
        },
        {
          value: '',
          id: 'view',
        },
      ],
    },
    {
      id: '4',
      cells: [
        {
          value: <div className={styles.dummy} />,
          id: 'name',
        },
        {
          value: '',
          id: 'dateSubmitted',
        },
        {
          value: '',
          id: 'form',
        },
        {
          value: '',
          id: 'cycle',
        },
        {
          value: '',
          id: 'view',
        },
      ],
    },
  ];

  const tableHeaders = [
    {
      value: t('pages.habitat.affiliate.home.latestPendingApplications.name'),
      id: 'name',
    },
    {
      value: t(
        'pages.habitat.affiliate.home.latestPendingApplications.dateSubmitted'
      ),
      id: 'dateSubmitted',
    },
    {
      value: t('pages.habitat.affiliate.home.latestPendingApplications.form'),
      id: 'form',
    },
    {
      value: t('pages.habitat.affiliate.home.latestPendingApplications.cycle'),
      id: 'cycle',
    },
    {
      value: t('pages.habitat.affiliate.home.latestPendingApplications.view'),
      id: 'view',
    },
  ];

  const loadingCells = [
    {
      value: <Skeleton variation="text" numOfCharacters={15} />,
      id: 'name',
    },
    {
      value: <Skeleton variation="text" numOfCharacters={10} />,
      id: 'dateSubmitted',
    },
    {
      value: <Skeleton variation="text" numOfCharacters={20} />,
      id: 'form',
    },
    {
      value: <Skeleton variation="text" numOfCharacters={12} />,
      id: 'cycle',
    },
    {
      id: 'view',
      value: <Skeleton className={styles.buttonSkeleton} />,
    },
  ];

  return (
    <div className={styles.page}>
      <h3 className={`${titleStyle} ${styles.title}`}>
        {status === Status.PENDING ? (
          <Skeleton variation="text" numOfCharacters={10} />
        ) : (
          t('pages.habitat.affiliate.home.title', {
            name: value?.firstName,
          })
        )}
      </h3>
      <p className={`${subtitleStyle} ${styles.subtitle}`}>
        {t('pages.habitat.affiliate.home.subtitle')}
      </p>
      <div className={styles.grid}>
        <CustomCard className={styles.tableContainer}>
          <h4 className={`theme-subtitle-s2 ${styles.cardTitle}`}>
            {t('pages.habitat.affiliate.home.latestPendingApplications.title')}
          </h4>
          <Suspense
            fallback={
              <TableWithPaginator
                headers={tableHeaders}
                data={[0, 1, 2, 3].map((number) => ({
                  id: number,
                  cells: loadingCells,
                }))}
              />
            }
          >
            <Await resolve={homeData}>
              {({
                latestPendingApplications,
                usersOfLatestPendingApplications,
                rootForms,
                cycles,
              }: THomeData) => (
                <TableWithPaginator
                  headers={tableHeaders}
                  data={defaultRows.map((row, index) => {
                    const latestPendingApplication =
                      latestPendingApplications[index];
                    if (latestPendingApplication) {
                      const cycle = cycles.find(
                        (cycle) =>
                          cycle.id === latestPendingApplication.testcycleID
                      );

                      const rootForm = rootForms.find(
                        (rootForm) => rootForm.id === cycle?.rootformID
                      );

                      return {
                        id: latestPendingApplications[index].id,
                        cells: [
                          {
                            value: (() => {
                              const user =
                                usersOfLatestPendingApplications.find(
                                  (user) =>
                                    user.owner ===
                                    latestPendingApplication.ownerID
                                );

                              return user
                                ? `${user.firstName} ${user.lastName}`
                                : (
                                    latestPendingApplication.props as unknown as {
                                      name: string;
                                    }
                                  ).name;
                            })(),
                            id: 'name',
                          },
                          {
                            value: convertDateYYYYMMDDtoDDMMYYYY(
                              latestPendingApplication.submittedDate
                            ),
                            id: 'dateSubmitted',
                          },
                          {
                            value: rootForm?.name,
                            id: 'form',
                          },
                          {
                            value: cycle?.name,
                            id: 'cycle',
                          },
                          {
                            id: 'view',
                            value: (
                              <div className={styles.openButtonContainer}>
                                <Link
                                  to={`../${rootForm?.id}/${cycle?.id}/${latestPendingApplication.id}`}
                                >
                                  <IconButton
                                    className={styles.openButton}
                                    variation="not-outlined"
                                  >
                                    <MdOutlineOpenInNew
                                      size="24px"
                                      color="var(--amplify-colors-neutral-90)"
                                    />
                                  </IconButton>
                                </Link>
                              </div>
                            ),
                          },
                        ],
                      };
                    }
                    return row;
                  })}
                />
              )}
            </Await>
          </Suspense>
        </CustomCard>
        <CustomCard>
          <h4 className={`theme-subtitle-s2 ${styles.cardTitle}`}>
            {t('pages.habitat.affiliate.home.revisionsOutcome.title')}
          </h4>
          <div className={styles.chartContainer}>
            <Suspense
              fallback={
                <Skeleton
                  style={{ aspectRatio }}
                  className={styles.chartSkeleton}
                />
              }
            >
              <Await resolve={homeData}>
                {({ lastSixMonthsReviewedApplications }: THomeData) => (
                  <Bar
                    redraw
                    data={{
                      labels: getLastMonths(6),
                      datasets: [
                        {
                          label: t(
                            'pages.habitat.affiliate.home.revisionsOutcome.approved'
                          ),
                          data: getLastMonths(6).map((month) => {
                            const monthIndex = months.indexOf(month);
                            return lastSixMonthsReviewedApplications.filter(
                              (application) => {
                                const submittedDateMonth = dayjs(
                                  application.submittedDate
                                ).get('month');
                                return (
                                  submittedDateMonth === monthIndex &&
                                  application.reviewStatus ===
                                    ReviewStatus.ACCEPTED
                                );
                              }
                            ).length;
                          }),
                          backgroundColor: '#73BB90',
                          stack: 'approved',
                        },
                        {
                          label: t(
                            'pages.habitat.affiliate.home.revisionsOutcome.denied'
                          ),
                          data: getLastMonths(6).map((month) => {
                            const monthIndex = months.indexOf(month);
                            return lastSixMonthsReviewedApplications.filter(
                              (application) => {
                                const submittedDateMonth = dayjs(
                                  application.submittedDate
                                ).get('month');
                                return (
                                  submittedDateMonth === monthIndex &&
                                  application.reviewStatus ===
                                    ReviewStatus.DENIED
                                );
                              }
                            ).length;
                          }),
                          backgroundColor: '#FF8282',
                          stack: 'rejected',
                        },
                      ],
                    }}
                    options={{
                      responsive: true,

                      interaction: {
                        mode: 'index' as const,
                        intersect: false,
                      },
                      maintainAspectRatio: true,
                      aspectRatio: aspectRatio || 1,
                      scales: {
                        x: {
                          stacked: true,
                        },
                        y: {
                          stacked: true,
                        },
                      },
                    }}
                    plugins={[
                      {
                        id: 'legendOffset',
                        beforeInit(chart) {
                          const { legend } = chart;

                          if (!legend) {
                            return;
                          }

                          const originalFit = legend.fit;

                          legend.fit = function fit() {
                            originalFit.bind(chart.legend)();

                            // eslint-disable-next-line react/no-this-in-sfc
                            this.height += 15;
                          };
                        },
                      },
                    ]}
                  />
                )}
              </Await>
            </Suspense>
          </div>
        </CustomCard>
        <CustomCard>
          <h4 className={`theme-subtitle-s2 ${styles.cardTitle}`}>
            {t('pages.habitat.affiliate.home.submissionsToday')}
          </h4>
          <div className={styles.bigNumberContainer}>
            <span className={`theme-headline-large ${styles.bigNumber}`}>
              <Suspense
                fallback={<Skeleton variation="text" numOfCharacters={3} />}
              >
                <Await resolve={homeData}>
                  {({ todayApplications }: THomeData) =>
                    todayApplications.length
                  }
                </Await>
              </Suspense>
            </span>
          </div>
        </CustomCard>
      </div>
    </div>
  );
};

export default AffiliateHomePage;
