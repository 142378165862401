import { View, Flex, Text } from '@aws-amplify/ui-react';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CustomButton from 'components/CustomButton';
import { MdAttachFile, MdOutlineClose } from 'react-icons/md';
import styles from './FileInput.module.css';

const FileInput = ({
  maxFileCount,
  label,
  onChange,
  files = [],
  multiple,
  isRequired,
  accept,
  isDisabled,
  hasError,
  errorMessage,
  onInvalid,
  onBlur,
}) => {
  const { t } = useTranslation();
  const filesInputRef = useRef();

  const handleOnChange = (event) => {
    const newFiles = [...event.currentTarget.files];
    const filteredFiles = newFiles.filter(
      (newFile) =>
        files.find((file) => file.name === newFile.name) === undefined
    );
    const mergedFiles = [...files, ...filteredFiles];
    onChange(
      maxFileCount !== undefined && mergedFiles.length > maxFileCount
        ? mergedFiles.slice(0, maxFileCount)
        : mergedFiles
    );
  };

  const handleOnRemove = (fileName) => {
    const filteredFiles = files.filter((file) => file.name !== fileName);
    onChange(filteredFiles);
  };

  useEffect(() => {
    if (filesInputRef.current) {
      if (files) {
        const dataTransfer = new DataTransfer();
        files.forEach((file) => dataTransfer.items.add(file));
        filesInputRef.current.files = dataTransfer.files;
      }
    }
  }, [files]);

  return (
    <View
      width="100%"
      border="thin"
      borderColor="lightgray"
      borderStyle="solid"
      padding="1rem"
      borderRadius="medium"
    >
      <Flex alignItems="center">
        <View flex={1}>
          <Text>{label}</Text>
          <View className={`theme-body-small ${styles.descriptiveText}`}>
            <Text variation="secondary">{`${t(
              'components.fileInput.addFiles'
            )}${!multiple ? ` ${t('components.fileInput.oneByOne')}` : ''} ${
              maxFileCount !== undefined
                ? `(${maxFileCount} ${t('components.fileInput.filesMax')}.)`
                : ''
            }`}</Text>
          </View>

          {hasError && (
            <View className="theme-body-small">
              <Text variation="error">{errorMessage}</Text>
            </View>
          )}
        </View>
        <input
          type="file"
          accept={accept}
          onChange={handleOnChange}
          ref={filesInputRef}
          required={isRequired}
          multiple={multiple}
          disabled={
            isDisabled ||
            onChange === undefined ||
            (maxFileCount !== undefined &&
              files &&
              files.length >= maxFileCount)
          }
          onInvalid={onInvalid}
          onBlur={onBlur}
          className={styles.fileInput}
        />
        <CustomButton
          onClick={() => filesInputRef.current.click()}
          title={t('components.fileInput.openFileBrowser')}
        >
          <MdAttachFile size={24} />
        </CustomButton>
      </Flex>

      {files && files.length > 0 && (
        <>
          <Text
            fontSize="0.875rem"
            fontStyle="italic"
            marginTop="0.5rem"
            marginBottom="8px"
            variation="secondary"
          >
            {t('components.fileInput.selectedFiles')}
          </Text>

          <Flex
            direction="column"
            gap="0.5rem"
            maxHeight="12.5rem"
            minHeight={0}
            overflow="auto"
          >
            {Array.from(files).map((file) => (
              <Flex key={file.name} className={styles.fileRow}>
                <Text grow={1} lineHeight={1.25}>
                  {file.name}
                </Text>
                <CustomButton
                  title="Remove file"
                  onClick={() => handleOnRemove(file.name)}
                  isDisabled={isDisabled}
                  className={styles.iconButton}
                >
                  <MdOutlineClose />
                </CustomButton>
              </Flex>
            ))}
          </Flex>
        </>
      )}
    </View>
  );
};

FileInput.propTypes = {
  maxFileCount: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  files: PropTypes.array,
  multiple: PropTypes.bool,
  isRequired: PropTypes.bool,
  accept: PropTypes.string,
  isDisabled: PropTypes.bool,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  onInvalid: PropTypes.func,
  onBlur: PropTypes.func,
};

export default FileInput;
