import { DataStore, SortDirection } from 'aws-amplify/datastore';
import dayjs from 'dayjs';
import {
  Habitat,
  ReviewStatus,
  SubmissionStatus,
  TestApplication,
  TestCycle,
  User,
} from 'models';
import { defer, LoaderFunction } from 'react-router-dom';
import { untilDataStoreIsReady } from 'utils/dataStore';

const getHomeData = async (habitat: string | undefined) => {
  await untilDataStoreIsReady();

  const habitats = await DataStore.query(Habitat, (c) => c.urlName.eq(habitat));

  if (habitats.length === 0) {
    throw new Response('Habitat not Found', { status: 404 });
  }

  const rootForms = await habitats[0].RootForms.toArray();

  let cycles: TestCycle[] = [];

  for (const rootForm of rootForms) {
    const cyclesOfRootForm = await rootForm.Cycles.toArray();

    cycles = [...cycles, ...cyclesOfRootForm];
  }

  const latestPendingApplications =
    cycles.length > 0
      ? await DataStore.query(
          TestApplication,
          (c) =>
            c.and((c2) => [
              c2.or((c3) => cycles.map((cycle) => c3.testcycleID.eq(cycle.id))),
              c2.reviewStatus.eq(ReviewStatus.PENDING),
              c2.submissionStatus.eq(SubmissionStatus.COMPLETED),
            ]),
          {
            limit: 4,
            sort: (s) => s.submittedDate(SortDirection.DESCENDING),
          }
        )
      : [];

  const usersOfLatestPendingApplications =
    latestPendingApplications.length > 0
      ? await DataStore.query(User, (c) =>
          c.or((c2) =>
            latestPendingApplications.map((application) =>
              c2.owner.eq(application.ownerID || '')
            )
          )
        )
      : [];

  const sixMonthsAgoDate = dayjs().subtract(6, 'months').format('YYYY-MM-DD');

  const lastSixMonthsReviewedApplications =
    cycles.length > 0
      ? await DataStore.query(
          TestApplication,
          (c) =>
            c.and((c2) => [
              c2.or((c3) => cycles.map((cycle) => c3.testcycleID.eq(cycle.id))),
              c2.or((c4) => [
                c4.reviewStatus.eq(ReviewStatus.ACCEPTED),
                c4.reviewStatus.eq(ReviewStatus.DENIED),
              ]),
              c2.submittedDate.ge(sixMonthsAgoDate),
            ]),
          {
            sort: (s) => s.submittedDate(SortDirection.DESCENDING),
          }
        )
      : [];

  const todayApplications =
    cycles.length > 0
      ? await DataStore.query(
          TestApplication,
          (c) =>
            c.and((c2) => [
              c2.or((c3) => cycles.map((cycle) => c3.testcycleID.eq(cycle.id))),
              c2.submittedDate.eq(dayjs().format('YYYY-MM-DD')),
            ]),
          {
            sort: (s) => s.submittedDate(SortDirection.DESCENDING),
          }
        )
      : [];

  return {
    latestPendingApplications,
    usersOfLatestPendingApplications,
    lastSixMonthsReviewedApplications,
    todayApplications,
    rootForms,
    cycles,
  };
};

export type THomeData = Awaited<Promise<ReturnType<typeof getHomeData>>>;

const homeLoader: LoaderFunction = async ({ params }) => {
  const { habitat } = params;

  const homeData = getHomeData(habitat);

  return defer({ homeData });
};

export default homeLoader;
