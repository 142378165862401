import { DataStore } from 'aws-amplify/datastore';
import { Habitat } from 'models';
import { defer, LoaderFunction } from 'react-router-dom';
import { untilDataStoreIsReady } from 'utils/dataStore';

const getReportsData = async (habitat: string | undefined) => {
  await untilDataStoreIsReady();

  const habitats = await DataStore.query(Habitat, (c) => c.urlName.eq(habitat));

  if (habitats.length === 0) {
    throw new Response('Habitat not Found', { status: 404 });
  }

  const reports = await habitats[0].Reports.toArray();

  const rootForms = await habitats[0].RootForms.toArray();

  const cyclesResponse = await Promise.allSettled(
    rootForms.map((rootForm) => rootForm.Cycles.toArray())
  );

  const cycles = cyclesResponse
    .map((response) => {
      if (response.status === 'fulfilled') {
        return response.value;
      }
      return [];
    })
    .flat();

  return {
    reports,
    rootForms,
    cycles,
  };
};

export type TReportsData = Awaited<Promise<ReturnType<typeof getReportsData>>>;

const reportsLoader: LoaderFunction = async ({ params }) => {
  const { habitat } = params;

  return defer({ reportsData: getReportsData(habitat) });
};

export default reportsLoader;
