import { Suspense, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Await,
  useNavigate,
  useRevalidator,
  useRouteLoaderData,
} from 'react-router-dom';
import { DataStore, SortDirection } from 'aws-amplify/datastore';
import { Button, useBreakpointValue } from '@aws-amplify/ui-react';
import { MdOutlineOpenInNew, MdFilterList } from 'react-icons/md';
import { throttle } from 'lodash';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import Chip from 'components/Chip';
import GoBack from 'components/GoBack';
import TableWithPaginator from 'components/TableWithPaginator';
import { TestCycle } from 'models';
import { convertDateYYYYMMDDtoDDMMYYYY } from 'utils/dates';
import useHabitat from 'hooks/utils/useHabitat';
import CustomButton from 'components/CustomButton';
import ResultsCounter from 'components/ResultsCounter';
import { TRootFormData } from 'router/loaders/rootForm';
import Skeleton from 'components/Skeleton';
import Filters from './components/filters';
import NewCycle from './components/newCycle';
import styles from './styles.module.css';
import { Inputs } from './types';
import CycleButton from './components/CycleButton';

const loadingCells = [
  { value: <Skeleton variation="text" numOfCharacters={12} />, id: 'name' },
  {
    value: <Skeleton variation="text" numOfCharacters={10} />,
    id: 'startDate',
  },
  { value: <Skeleton variation="text" numOfCharacters={10} />, id: 'endDate' },
  {
    value: <Skeleton className={styles.chipSkeleton} />,
    id: 'status',
  },
  {
    value: <Skeleton className={styles.buttonSkeleton} />,
    id: 'view',
  },
];

const CyclesPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { habitat } = useHabitat();

  const [showFilters, setShowFilters] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [filters, setFilters] = useState<Inputs>({
    startDate: '',
    endDate: '',
    status: null,
  });

  const isSmall = useBreakpointValue({
    base: true,
    medium: false,
  }) as boolean;

  const titleStyle = useBreakpointValue({
    base: 'theme-subtitle-s1',
    large: 'theme-headline-medium',
  });

  const { rootFormData } = useRouteLoaderData('rootForm') as {
    rootFormData: Promise<TRootFormData>;
  };

  const revalidator = useRevalidator();

  const tableHeader = [
    {
      id: 'name',
      value: t('pages.habitat.affiliate.cycles.table.name'),
    },
    {
      id: 'startDate',
      value: t('pages.habitat.affiliate.cycles.table.startDate'),
    },
    {
      id: 'endDate',
      value: t('pages.habitat.affiliate.cycles.table.endDate'),
    },
    {
      id: 'status',
      value: t('pages.habitat.affiliate.cycles.table.status'),
    },
    {
      id: 'view',
      value: t('pages.habitat.affiliate.cycles.table.view'),
    },
  ];

  const getCycles = useCallback(async () => {
    const { rootForm } = await rootFormData;
    if (habitat) {
      const cyclesResponse = await DataStore.query(
        TestCycle,
        (c1) =>
          c1.and((c2) => {
            const criteriaArray = [];

            criteriaArray.push(c2.rootformID.eq(rootForm.id));

            if (filters?.status === 'open' || filters.status === 'close') {
              criteriaArray.push(c2.isOpen.eq(filters.status === 'open'));
            }

            if (filters?.startDate) {
              criteriaArray.push(c2.startDate.ge(filters.startDate));
            }

            if (filters?.endDate) {
              criteriaArray.push(c2.endDate.le(filters.endDate));
            }

            return criteriaArray;
          }),
        {
          sort: (s) => s.startDate(SortDirection.DESCENDING),
        }
      );

      const openCyclesResponse = await DataStore.query(TestCycle, (c1) =>
        c1.and((c2) => {
          const criteriaArray = [c2.isOpen.eq(true)];

          criteriaArray.push(c2.rootformID.eq(rootForm.id));

          return criteriaArray;
        })
      );

      return {
        cycles: cyclesResponse,
        openCycles: openCyclesResponse,
        rootForm,
      };
    }
  }, [filters, habitat, rootFormData]);

  type TCycles = Awaited<Promise<ReturnType<typeof getCycles>>>;

  const getCyclesData = useMemo(() => getCycles(), [getCycles]);

  const onClickView = (id: string) => {
    navigate(`./${id}`);
  };

  const breadCrumbsItems = [
    { label: t('pages.habitat.affiliate.forms.name'), to: '../forms' },
    {
      label: t('pages.habitat.affiliate.cycles.name'),
    },
  ];

  return (
    <div className={styles.page}>
      <div className={styles.cta}>
        <BreadCrumbs items={breadCrumbsItems} />
        <div className={styles.title}>
          <GoBack to="../forms" />
          <p className={`${titleStyle} ${styles.titleText}`}>
            {t('pages.habitat.affiliate.cycles.title')}
          </p>
        </div>
      </div>
      <div className={styles.applications}>
        <div className={styles.table_options}>
          <div className={styles.table_title}>
            <p className={`${styles.neutral_100} theme-subtitle-s2`}>
              {t('pages.habitat.affiliate.cycles.table.title')}
            </p>
            <Suspense fallback={<ResultsCounter skeleton number={0} />}>
              <Await resolve={getCyclesData}>
                {(response: TCycles) => {
                  if (revalidator.state === 'loading') {
                    return <ResultsCounter skeleton number={0} />;
                  }

                  if (!response) {
                    return;
                  }

                  const { cycles } = response;

                  return <ResultsCounter number={cycles.length} />;
                }}
              </Await>
            </Suspense>
          </div>
          <Suspense>
            <Await resolve={getCyclesData}>
              {(response: TCycles) => {
                if (revalidator.state === 'loading') {
                  return;
                }

                if (!response) {
                  return;
                }

                const { openCycles, rootForm } = response;

                return (
                  <>
                    <div className={styles.options}>
                      <CustomButton
                        onClick={throttle(() => {
                          setShowFilters((prev) => !prev);
                        }, 500)}
                        icon={isSmall ? undefined : <MdFilterList />}
                      >
                        {isSmall ? (
                          <MdFilterList size="24px" />
                        ) : (
                          t('pages.habitat.affiliate.cycles.button.filter')
                        )}
                      </CustomButton>
                      <CycleButton
                        isSmall={isSmall}
                        isACycleOpen={openCycles.length > 0}
                        onClick={throttle(() => {
                          setShowModal(true);
                        }, 500)}
                      />
                    </div>
                    {showFilters && (
                      <Filters
                        filters={filters}
                        setFilters={(data) => setFilters(data)}
                        close={() => setShowFilters(false)}
                      />
                    )}
                    {showModal && (
                      <NewCycle
                        refetch={() => revalidator.revalidate()}
                        openCycle={
                          openCycles.length > 0 ? openCycles[0] : undefined
                        }
                        rootForm={rootForm}
                        open={showModal}
                        close={() => setShowModal(false)}
                      />
                    )}
                  </>
                );
              }}
            </Await>
          </Suspense>
        </div>
        <Suspense
          fallback={
            <TableWithPaginator
              headers={tableHeader}
              data={[0, 1, 2, 3, 4].map((number) => ({
                id: number,
                cells: loadingCells,
              }))}
            />
          }
        >
          <Await resolve={getCyclesData}>
            {(response: TCycles) => {
              if (revalidator.state === 'loading') {
                return (
                  <TableWithPaginator
                    headers={tableHeader}
                    data={[0, 1, 2, 3, 4].map((number) => ({
                      id: number,
                      cells: loadingCells,
                    }))}
                  />
                );
              }

              if (!response) {
                return;
              }

              const { cycles } = response;

              const formattedCycles = cycles.map(
                ({
                  id,
                  name,
                  startDate,
                  endDate,
                  isOpen,
                  createdAt,
                }: TestCycle) => ({
                  id,
                  name,
                  startDate: convertDateYYYYMMDDtoDDMMYYYY(
                    startDate.split('T')[0]
                  ),
                  endDate: endDate
                    ? convertDateYYYYMMDDtoDDMMYYYY(endDate)
                    : '',
                  status: isOpen,
                  createdAt,
                })
              );

              return (
                <TableWithPaginator
                  headers={tableHeader}
                  data={formattedCycles.map((data) => ({
                    id: data.id,
                    cells: [
                      { value: data.name, id: 'name' },
                      { value: data.startDate, id: 'startDate' },
                      { value: data.endDate, id: 'endDate' },
                      {
                        value: (
                          <Chip
                            variation={data.status ? 'success' : 'danger'}
                            text={
                              data.status
                                ? t(
                                    'pages.habitat.affiliate.cycles.status.open'
                                  )
                                : t(
                                    'pages.habitat.affiliate.cycles.status.closed'
                                  )
                            }
                          />
                        ),
                        id: 'status',
                      },
                      {
                        value: (
                          <Button
                            variation="link"
                            padding="0"
                            onClick={throttle(() => onClickView(data.id), 500)}
                          >
                            <MdOutlineOpenInNew
                              size="24px"
                              color="var(--amplify-colors-neutral-90)"
                            />
                          </Button>
                        ),
                        id: 'view',
                      },
                    ],
                  }))}
                />
              );
            }}
          </Await>
        </Suspense>
      </div>
    </div>
  );
};

export default CyclesPage;
