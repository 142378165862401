import React, { Suspense, useCallback, useEffect, useState } from 'react';
import FormLayout from 'components/FormLayout';
import { Form, Wizard } from '@formio/react';
import { Await, useRouteLoaderData } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useHabitat from 'hooks/utils/useHabitat';
import { isEmpty } from 'lodash';
import useAsync from 'hooks/utils/useAsync';
import { Options } from '@formio/react/lib/components/Form';
import { Status } from 'utils/enums';
import CustomButton from 'components/CustomButton';
import { TCycleData } from 'router/loaders/cycle';
import { Loader } from '@aws-amplify/ui-react';
import { TRootFormData } from 'router/loaders/rootForm';
import style from './AffiliateApplicationTest.module.css';

const FORMIO_URL = process.env.REACT_APP_FORMIO_URL;

const AffiliateTestApplication = () => {
  const [formReady, setFormReady] = useState<typeof Wizard>();

  const [reviewMode, setReviewMode] = useState(false);

  const [submission, setSubmission] = useState<object>();

  const handleFormReady = (form: typeof Wizard) => setFormReady(form);

  const { cycleData } = useRouteLoaderData('cycle') as {
    cycleData: Promise<TCycleData>;
  };

  const { rootFormData } = useRouteLoaderData('rootForm') as {
    rootFormData: Promise<TRootFormData>;
  };

  const { t: rootFormT, i18n } = useTranslation('rootForm');

  const { t } = useTranslation();

  const { language } = i18n;

  const { habitat } = useHabitat();

  const getData = useCallback(async (): Promise<
    | {
        data: {
          translations: Record<string, Record<string, string>>;
        };
      }
    | undefined
  > => {
    try {
      const { cycle } = await cycleData;
      const response = await fetch(
        `${FORMIO_URL}/language/submission?data.language=${language}&data.form=${cycle?.formUrl}`
      );

      const array = await response.json();

      let translations = {};

      if (array.length > 0) {
        const { data } = array[0];
        const { translation } = data;
        Object.keys(translation).forEach((key) => {
          const newKey = key.replace(/__DOT__/g, '.').replace(/__USD__/g, '$');
          translation[newKey] = translation[key];
          if (newKey !== key) {
            delete translation[key];
          }
        });

        translations = {
          [`${language}`]: translation,
        };
      }

      return {
        data: {
          translations,
        },
      };
    } catch (error) {
      console.log('Error getting translations');
    }
  }, [cycleData, language]);

  const { value, status } = useAsync({
    asyncFunction: getData,
  });

  useEffect(() => {
    const setSupportQuestion = async () => {
      const { rootForm } = await rootFormData;

      if (rootForm.supportQuestion) {
        i18n.addResource(
          'en',
          'rootForm',
          'supportQuestion',
          rootForm.supportQuestion?.en
        );

        i18n.addResource(
          'es',
          'rootForm',
          'supportQuestion',
          rootForm.supportQuestion?.es || rootForm.supportQuestion?.en
        );
      }
    };

    setSupportQuestion();

    return () => {
      i18n.removeResourceBundle('en', 'rootForm');
      i18n.removeResourceBundle('es', 'rootForm');
    };
  }, [rootFormData, i18n, t]);

  if (status === Status.PENDING || !value) {
    return <Loader size="large" />;
  }

  return reviewMode ? (
    <div className={`${style.formContainer}`} style={{ padding: '2rem 0rem' }}>
      <Suspense fallback={<Loader size="large" />}>
        <Await resolve={cycleData}>
          {({ cycle, formUrl, formSchema }: TCycleData) => {
            const options = {
              additional: {
                habitat,
                openCycle: cycle,
              },
              language: isEmpty(value?.data.translations) ? 'en' : language,
              i18n: value?.data.translations,
              readOnly: reviewMode,
              flatten: reviewMode,
            } as Options;
            return (
              <Form
                key={`review-${language}`}
                url={formUrl}
                form={formSchema}
                options={options}
                submission={submission}
                onSubmit={(newSubmission: object) => {
                  setSubmission(newSubmission);
                  setReviewMode(true);
                }}
              />
            );
          }}
        </Await>
      </Suspense>

      <div className={`${style.buttonContainer}`}>
        <CustomButton
          onClick={() => {
            setReviewMode(false);
            setFormReady(undefined);
          }}
        >
          {t('pages.habitat.affiliate.cycles.cycle.applicationTest.goBack')}
        </CustomButton>
      </div>
    </div>
  ) : (
    <Suspense fallback={<Loader size="large" />}>
      <Await resolve={cycleData}>
        {({ cycle, formUrl, formSchema }: TCycleData) => {
          const options = {
            additional: {
              habitat,
              openCycle: cycle,
            },
            language: isEmpty(value?.data.translations) ? 'en' : language,
            i18n: value?.data.translations,
            readOnly: reviewMode,
            flatten: reviewMode,
          } as Options;
          return (
            <FormLayout
              formReady={formReady}
              cycle={cycle}
              serializedHelpContent={rootFormT('supportQuestion')}
              deactivatePosthog
            >
              <div
                className={`${style.formContainer}`}
                style={{ padding: '2rem 1rem' }}
              >
                <Form
                  key={`real-${language}`}
                  url={formUrl}
                  form={formSchema}
                  options={options}
                  submission={submission}
                  formReady={handleFormReady}
                  onSubmit={(newSubmission: object) => {
                    setSubmission(newSubmission);
                    setReviewMode(true);
                  }}
                />
              </div>
            </FormLayout>
          );
        }}
      </Await>
    </Suspense>
  );
};

export default AffiliateTestApplication;
