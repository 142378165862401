import React from 'react';
import ExpandableCard from 'components/ExpandableCard';
import { User } from 'models';
import { useTranslation } from 'react-i18next';
import Skeleton from 'components/Skeleton';
import styles from './ApplicantTab.module.css';

interface IApplicantTabProps {
  user?: User;
  attributes?: { Name: string; Value: string }[];
  skeleton?: boolean;
}

const ApplicantTab = ({ user, attributes, skeleton }: IApplicantTabProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <ExpandableCard>
        <div className={styles.content}>
          <span className={styles.title}>
            {t('components.applicantTab.name')}
          </span>
          <br />
          <span>
            {skeleton ? (
              <Skeleton variation="text" numOfCharacters={14} />
            ) : (
              `${user?.firstName} ${user?.lastName}`
            )}
          </span>
        </div>
      </ExpandableCard>
      <ExpandableCard>
        <div className={styles.content}>
          <span className={styles.title}>
            {t('components.applicantTab.email')}
          </span>
          <br />
          <span>
            {skeleton ? (
              <Skeleton variation="text" numOfCharacters={20} />
            ) : (
              `${
                attributes?.find((attribute) => attribute.Name === 'email')
                  ?.Value
              }`
            )}
          </span>
        </div>
      </ExpandableCard>
      <ExpandableCard>
        <div className={styles.content}>
          <span className={styles.title}>
            {t('components.applicantTab.phone')}
          </span>
          <br />
          <span>
            {skeleton ? (
              <Skeleton variation="text" numOfCharacters={14} />
            ) : (
              `${
                attributes?.find(
                  (attribute) => attribute.Name === 'phone_number'
                )?.Value
              }`
            )}
          </span>
        </div>
      </ExpandableCard>
    </div>
  );
};

export default ApplicantTab;
