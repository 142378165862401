import { EditorState } from 'lexical';
import { ReportStatus } from 'models';
import { z } from 'zod';

export const reportSchema = z.object({
  title: z.string(),
  testCycleId: z.string(),
  content: z.custom<EditorState>(),
  status: z.nativeEnum(ReportStatus),
});

export type TReportSchema = z.infer<typeof reportSchema>;
