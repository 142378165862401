import { DataStore } from 'aws-amplify/datastore';
import { Habitat } from 'models';
import { defer, LoaderFunction } from 'react-router-dom';
import { untilDataStoreIsReady } from 'utils/dataStore';

const getHabitatData = async (habitat: string | undefined) => {
  await untilDataStoreIsReady();

  const habitats = await DataStore.query(Habitat, (c) => c.urlName.eq(habitat));

  if (habitats.length === 0) {
    throw new Response('Habitat not Found', { status: 404 });
  }

  return habitats[0];
};

export type THabitatData = Awaited<Promise<ReturnType<typeof getHabitatData>>>;

const habitatLoader: LoaderFunction = async ({ params }) => {
  const { habitat } = params;

  return defer({ habitatData: getHabitatData(habitat) });
};

export default habitatLoader;
