// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ReportStatus = {
  "PENDING": "PENDING",
  "COMPLETED": "COMPLETED"
};

const ReviewStatus = {
  "ACCEPTED": "ACCEPTED",
  "PENDING": "PENDING",
  "DENIED": "DENIED",
  "RETURNED": "RETURNED"
};

const Sexs = {
  "MALE": "MALE",
  "FEMALE": "FEMALE",
  "OTHER": "OTHER"
};

const UserTypes = {
  "AFFILIATE": "AFFILIATE",
  "APPLICANT": "APPLICANT"
};

const RootFormStatusTypes = {
  "ACTIVE": "ACTIVE",
  "PENDING": "PENDING"
};

const ApplicationTypes = {
  "ONLINE": "ONLINE",
  "PAPER": "PAPER"
};

const SubmissionStatus = {
  "INCOMPLETE": "INCOMPLETE",
  "COMPLETED": "COMPLETED"
};

const { Report, Maintenance, User, RootForm, Decision, FormAnswer, Note, TestCycle, TestApplication, Habitat, MultiLangText, SidebarName, ApplicantProps, AffiliateProps, GalleryItem, HabitatProps } = initSchema(schema);

export {
  Report,
  Maintenance,
  User,
  RootForm,
  Decision,
  FormAnswer,
  Note,
  TestCycle,
  TestApplication,
  Habitat,
  ReportStatus,
  ReviewStatus,
  Sexs,
  UserTypes,
  RootFormStatusTypes,
  ApplicationTypes,
  SubmissionStatus,
  MultiLangText,
  SidebarName,
  ApplicantProps,
  AffiliateProps,
  GalleryItem,
  HabitatProps
};