import { get } from 'aws-amplify/api';
import { DataStore } from 'aws-amplify/datastore';
import { Habitat, TestApplication, TestCycle, User } from 'models';
import { defer, LoaderFunction } from 'react-router-dom';
import { untilDataStoreIsReady } from 'utils/dataStore';

export interface IUsersOfApplications extends User {
  attributes: {
    email: string;
    phone: string;
  };
}

const getUsers = async (habitat: string | undefined) => {
  await untilDataStoreIsReady();

  const habitats = await DataStore.query(Habitat, (c) => c.urlName.eq(habitat));

  if (habitats.length === 0) {
    throw new Response('Habitat not Found', { status: 404 });
  }

  const rootForms = await habitats[0].RootForms.toArray();

  let cycles: TestCycle[] = [];

  for (const rootForm of rootForms) {
    const cyclesOfRootForm = await rootForm.Cycles.toArray();

    cycles = [...cycles, ...cyclesOfRootForm];
  }

  const applications =
    cycles.length > 0
      ? await DataStore.query(TestApplication, (c) =>
          c.or((c2) => cycles.map((cycle) => c2.testcycleID.eq(cycle.id)))
        )
      : [];

  const usersOfApplicationsWithoutAttributes =
    applications.length > 0
      ? await DataStore.query(User, (c) =>
          c.or((c2) =>
            applications.map((application) =>
              c2.owner.eq(application.ownerID || 'unknown')
            )
          )
        )
      : [];

  const users: IUsersOfApplications[] = [];

  const promisesArray = usersOfApplicationsWithoutAttributes.map((user) =>
    (async () => {
      try {
        const attributesResponse = user.owner
          ? await get({
              apiName: 'userAPI',
              path: '/',
              options: {
                queryParams: {
                  sub: user.owner,
                },
              },
            }).response
          : undefined;

        if (attributesResponse?.statusCode === 200) {
          const body = (await attributesResponse.body.json()) as {
            attributes: Record<string, string>[];
          };

          const { attributes } = body;

          users.push({
            ...user,
            attributes: {
              email:
                attributes.find((attr) => attr.Name === 'email')?.Value || '',
              phone:
                attributes.find((attr) => attr.Name === 'phone_number')
                  ?.Value || '',
            },
          });
        }
      } catch (error) {
        console.error('User does not exist.');
      }
    })()
  );

  await Promise.allSettled(promisesArray);

  return users;
};

const usersLoader: LoaderFunction = async ({ params, request }) => {
  if (!request.url.endsWith('users')) {
    return;
  }

  const users = getUsers(params.habitat);

  return defer({
    users,
  });
};

export default usersLoader;
