import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Flex,
  Loader,
  SelectField,
  Text,
  TextField,
  View,
} from '@aws-amplify/ui-react';
import LexicalEditor from 'components/LexicalEditor';
import { ReportStatus, RootForm, TestCycle } from 'models';
import { useTranslation } from 'react-i18next';
import { stringToHumanReadable } from 'utils/strings';
import { EditorState } from 'lexical';
import { useMemo, useState } from 'react';
import { reportSchema, TReportSchema } from './ReportModal.schema';

interface IReportModalProperties {
  open: boolean;
  onClose: () => void;
  onValid: (data: TReportSchema) => void;
  loading: number;
  rootForms: RootForm[];
  cycles: TestCycle[];
}

const ReportModal = ({
  open,
  onClose,
  onValid,
  loading,
  rootForms,
  cycles,
}: IReportModalProperties) => {
  const {
    control,
    register: registerDecide,
    handleSubmit,
    formState: { errors },
  } = useForm<TReportSchema>({
    resolver: zodResolver(reportSchema),
    shouldUnregister: true,
    shouldFocusError: true,
  });

  const { t } = useTranslation();

  const [rootForm, setRootForm] = useState<string>(rootForms[0]?.id);

  const filteredCycles = useMemo(
    () => cycles.filter((cycle) => cycle.rootformID === rootForm),
    [cycles, rootForm]
  );

  return (
    <Modal
      title={t(
        'pages.habitat.affiliate.reports.components.reportModal.modalTitle'
      )}
      open={open}
      onClickClose={onClose}
      width="45rem"
    >
      <form onSubmit={handleSubmit(onValid)}>
        <TextField
          {...registerDecide('title')}
          label={t(
            'pages.habitat.affiliate.reports.components.reportModal.title.label'
          )}
          hasError={Boolean(errors?.title)}
          errorMessage={t(
            'pages.habitat.affiliate.reports.components.reportModal.title.error'
          )}
        />
        <br />
        <SelectField
          label={t(
            'pages.habitat.affiliate.reports.components.reportModal.form'
          )}
          value={rootForm}
          onChange={(e) => setRootForm(e.target.value)}
        >
          {rootForms.map((rootForm) => (
            <option key={rootForm.id} value={rootForm.id}>
              {rootForm.name}
            </option>
          ))}
        </SelectField>
        <br />
        <SelectField
          {...registerDecide('testCycleId')}
          label={t(
            'pages.habitat.affiliate.reports.components.reportModal.cycle.label'
          )}
          hasError={Boolean(errors?.testCycleId)}
          errorMessage={t(
            'pages.habitat.affiliate.reports.components.reportModal.cycle.error'
          )}
          disabled={filteredCycles.length === 0}
        >
          {filteredCycles
            .filter((cycle) => cycle.rootformID === rootForm)
            .map((cycle) => (
              <option key={cycle.id} value={cycle.id}>
                {cycle.name}
              </option>
            ))}
        </SelectField>
        <br />
        <SelectField
          {...registerDecide('status')}
          label={t(
            'pages.habitat.affiliate.reports.components.reportModal.status.label'
          )}
          hasError={Boolean(errors?.status)}
          errorMessage={t(
            'pages.habitat.affiliate.reports.components.reportModal.status.error'
          )}
        >
          <option value={ReportStatus.COMPLETED}>
            {stringToHumanReadable(ReportStatus.COMPLETED)}
          </option>
          <option value={ReportStatus.PENDING}>
            {stringToHumanReadable(ReportStatus.PENDING)}
          </option>
        </SelectField>
        <br />

        <Controller
          control={control}
          name="content"
          defaultValue={{} as EditorState}
          render={({ field: { onChange } }) => (
            <LexicalEditor
              onChange={(editorState: EditorState) =>
                onChange(editorState.toJSON()) as undefined
              }
              editable
            />
          )}
        />
        {loading > 0 && (
          <>
            <br />
            <View>
              <Text>
                {t(
                  'pages.habitat.affiliate.reports.components.reportModal.creating'
                )}
              </Text>
              <Loader variation="linear" />
            </View>
          </>
        )}
        <Flex justifyContent="end" marginTop="1rem">
          <Button
            variation="destructive"
            onClick={onClose}
            isDisabled={loading > 0}
          >
            {t('pages.habitat.affiliate.reports.components.reportModal.cancel')}
          </Button>
          <Button type="submit" isDisabled={loading > 0}>
            {t('pages.habitat.affiliate.reports.components.reportModal.create')}
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};

ReportModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onValid: PropTypes.func,
  loading: PropTypes.number,
  customStatus: PropTypes.array,
  isOnline: PropTypes.bool,
};

export default ReportModal;
