import { get } from 'aws-amplify/api';
import { DataStore } from 'aws-amplify/datastore';
import { TestApplication, User } from 'models';
import { defer, LoaderFunction } from 'react-router-dom';
import { untilDataStoreIsReady } from 'utils/dataStore';

const getApplicationData = async (applicationId: string | undefined) => {
  if (!applicationId) {
    throw new Response('Application not specified.', { status: 400 });
  }

  await untilDataStoreIsReady();

  const application = await DataStore.query(TestApplication, applicationId);

  if (!application) {
    throw new Response('Application not found.', { status: 404 });
  }

  const formAnswers = await application.FormAnswers.toArray();

  const decisions = await application.Decisions.toArray();

  const user = await DataStore.query(User, (c) =>
    c.owner.eq(application.ownerID || '')
  );

  const attributesResponse = application.ownerID
    ? await get({
        apiName: 'userAPI',
        path: '/',
        options: {
          queryParams: {
            sub: application.ownerID || '',
          },
        },
      }).response
    : undefined;

  const attributes = attributesResponse
    ? ((await attributesResponse.body.json()) as {
        attributes: {
          Name: string;
          Value: string;
        }[];
      })
    : undefined;

  return {
    application,
    formAnswers,
    decisions,
    user: user.length > 0 ? user[0] : undefined,
    attributes,
  };
};

export type TApplicationData = Awaited<
  Promise<ReturnType<typeof getApplicationData>>
>;

const applicationLoader: LoaderFunction = async ({ params }) => {
  const { applicationId } = params;

  return defer({
    applicationData: getApplicationData(applicationId),
  });
};

export default applicationLoader;
